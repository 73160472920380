import { Link } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";

import  "./topbar.css"


export default function Topbar (){
  return (
    <div class="topbar">
        <Link class="menue" to={"/"}><AiOutlineMenu /></Link>
        <header>Experimental Tools</header>
    </div>
  );
};