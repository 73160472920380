import React, { useState } from "react";
import "./pagestyle.css"


const Temperature = () => {
    let [temp, setTemp] = useState({
        c: 0,
        f: 0,
        k: 0,
    });

    const CelsiusHandler = (e) => {
        e.preventDefault();
        const value = parseFloat(e.target.value);
        setTemp({
            ...temp,
            c: value,
            f: value*1.8+32,
            k: value+273.15
        });
    };

    const FahrenheitHandler = (e) => {
        e.preventDefault();
        const value = parseFloat(e.target.value);
        setTemp({
            ...temp,
            c: (value/1.8)-32,
            f: value,
            k: (value/1.8-32)+273.15
        });
    };

    const KelvinHandler = (e) => {
        e.preventDefault();
        const value = parseFloat(e.target.value);
        setTemp({
            ...temp,
            c: value-273.15,
            f: (value-273.15)*1.8+32,
            k: value
        });
    };

    return (
        <div class="wrapper">
            <header>Convert Temperatures</header><br></br>
            <div class="tempwrapper">
                <text>Grad Celsius:</text>
                <input Value={temp.c} onChange={CelsiusHandler} class="input" label="Outlined" variant="outlined" />
                <text>Grad Fahrenheit:</text>
                <input Value={temp.f} onChange={FahrenheitHandler} class="input" label="Outlined" variant="outlined" />
                <text>Grad Kelvin:</text>
                <input Value={temp.k} onChange={KelvinHandler} class="input" label="Outlined" variant="outlined" />
            </div>
        </div>
    );
};
 
export default Temperature;