import React, { useState, useEffect } from 'react';
import "./pagestyle.css"
 
export default function Home () {
    const [currentTime, setCurrentTime] = useState(0);

    useEffect(() => {
      fetch('/api/time').then(res => res.json()).then(data => {
        setCurrentTime(data.time);
      });
    }, []);
    
    return (
      <div class="wrapper">
            <header>Welcome to my Page.</header><br></br>
            <text>The current time is {currentTime}.</text>
      </div>
    );
};